import "./App.css";
import { useEffect, useState } from "react";
import { auth, db, logOut } from "./firebase";
import { Route, Routes, useNavigate } from "react-router-dom";
import { RColumn, Title1 } from "./comps/otherCOmps";
import HomePage from "./pages/homePage";
import LoginPage from "./pages/loginPage";
import { ToastContainer } from "react-toastify";
import Header from "./comps/header";
import "react-toastify/dist/ReactToastify.css";
import { doc, getDoc } from "firebase/firestore";
import { showErrorNotification } from "./comps/errorMessagesComp";
import WaitingPage from "./pages/waitingPage";

function StartingPage() {
  const navigate = useNavigate();
  const [isLogged, setIsLogged] = useState(false);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUserm) => {
      if (authUserm) {
        checkAdmin();
      } else {
        setIsLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  async function checkAdmin() {
    const adminRef = doc(db, "admin", auth.currentUser.uid);
    const adminDoc = await getDoc(adminRef);
    if (!adminDoc.exists()) {
      console.log("NO ADIM");
      setIsLoading(false);
      showErrorNotification("Non sei admin!");
      await logOut();
      setIsLogged(false);
      return;
    }
    setIsLoading(false);
    setIsLogged(true);
  }

  if (loading) {
    return (
      <RColumn
        height={"90vh"}
        width={"100%"}
        horizontalAlign="center"
        verticalAlignment="center"
      >
        <Title1>Loading</Title1>
      </RColumn>
    );
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <Header isLogged={isLogged} />
      <Routes>
        <Route path="/" element={isLogged ? <HomePage /> : <LoginPage />} />
        <Route path="/waiting" element={<WaitingPage />} />
      </Routes>
    </>
  );
}

export default StartingPage;

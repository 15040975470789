import {
  RRow,
  RText,
  SecondaryBtn,
  SpaceHorizontal,
  Title1,
  Title2,
} from "../comps/otherCOmps";
import { ColorPalette, setChangeDarkMode } from "../util/const";
import { useNavigate } from "react-router-dom";
import { logOut } from "../firebase";
import { useEffect } from "react";

const Header = ({ isLogged }) => {
  const navigate = useNavigate();
  const isMobile = false;
  const accedi = () => {
    navigate("/login");
  };

  return (
    <div style={{ position: "fixed", width: "100%", zIndex: 5 }}>
      <RRow
        horizontalAlign="space-between"
        padding={isMobile ? "20px 22px" : "20px 100px"}
        height={"40px"}
        bgColor={ColorPalette.containerColorDark}
      >
        <Title1>HAT ADMIN</Title1>
        <RRow>
          <SecondaryBtn onClick={() => navigate("/")}>Mentors</SecondaryBtn>
          <SpaceHorizontal />
          <SecondaryBtn onClick={() => navigate("/waiting")}>
            Waiting List
          </SecondaryBtn>
          <SpaceHorizontal />
          {!isLogged && <SecondaryBtn onClick={accedi}>Log in</SecondaryBtn>}
          {isLogged && <SecondaryBtn onClick={logOut}>Log out</SecondaryBtn>}
        </RRow>
      </RRow>
    </div>
  );
};

export default Header;

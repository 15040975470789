import "./App.css";
import { ColorPalette } from "./util/const";
import { BrowserRouter, HashRouter } from "react-router-dom";
import StartingPage from "./startingPage";

function App() {
  return (
    <div
      className="App"
      style={{ backgroundColor: ColorPalette.bgColorDark, minHeight: "100vh" }}
    >
      <HashRouter>
        <StartingPage />
      </HashRouter>
    </div>
  );
}

export default App;

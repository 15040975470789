import { useEffect, useState } from "react";
import {
  RColumn,
  RRow,
  RText,
  SecondaryBtn,
  Space,
  TerzButton,
  Title1,
  Title2,
} from "../comps/otherCOmps";
import { db, functions } from "../firebase";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

export default function WaitingPage() {
  const [artists, setArtist] = useState([]);

  const [loading, setLoading] = useState({});

  useEffect(() => {
    getArtists();
  }, []);

  async function getArtists() {
    const ref = collection(db, "users");
    const q = query(ref, where("isAccepted", "==", false));

    const res = await getDocs(q);

    setArtist(res.docs.map((d) => ({ ...d.data(), id: d.id })));
    console.log(res.docs.length);
  }
  async function approva(uid) {
    setLoading((p) => ({ ...p, [uid]: true }));
    try {
      const funct = httpsCallable(functions, "accettaUser");
      const res = await funct({ uid: uid });
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
    getArtists();
    setLoading((p) => ({ ...p, [uid]: false }));
  }
  return (
    <>
      <Space height="100px" />
      <Title2>Artist waiting list</Title2>
      <Space />
      <RColumn>
        {artists.map((a) => (
          <RRow key={a.id} horizontalAlign="space-between" width={"90%"}>
            <RText>{a.email}</RText>
            <RText>{a.nome}</RText>
            <RText>{a.cognome}</RText>
            <RText>{a.telefono}</RText>
            {loading[a.id] ? (
              <RText>Loading</RText>
            ) : (
              <SecondaryBtn onClick={() => approva(a.id)}>Accetta</SecondaryBtn>
            )}
          </RRow>
        ))}
      </RColumn>
    </>
  );
}
